<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        Match the exposure route with a way to minimize exposure occurring through that route.
      </p>

      <p class="mb-2" v-for="letter in letterOptions" :key="letter.inputName">
        {{ letter.text }}
        <v-select
          v-model="inputs[letter.inputName]"
          style="display: inline-block; max-width: 300px"
          class="my-1"
          :items="options"
          item-text="text"
          item-value="value"
          label="Select an option:"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'ChemUCI1LC_Q4S2_Q11',
  components: {StembleLatex, STextarea},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
      },
      options: [
        {text: 'Clean surfaces, wear gloves & lab coat', value: 'a'},
        {text: 'Do not put anything in your mouth that has been in lab', value: 'b'},
        {text: 'Cap flasks containing volatile liquids', value: 'c'},
        {text: 'Always wear goggles', value: 'd'},
        {text: 'Use a dustpan and broom to clean up broken glass', value: 'e'},
      ],
      letterOptions: [
        {text: 'Ingestion: ', inputName: 'input1'},
        {text: 'Eye exposure: ', inputName: 'input2'},
        {text: 'Inhalation: ', inputName: 'input3'},
        {text: 'Skin exposure: ', inputName: 'input4'},
        {text: 'Injection: ', inputName: 'input5'},
      ],
    };
  },
};
</script>
